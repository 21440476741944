<template>
  <div class="register">
    <nav-bar :nav-info='navInfo' ></nav-bar>
    <van-form validate-first @submit="onSubmit" class="sub_from"  @failed="onFailed" v-if="registerShow">
      <!-- 通过 validator 进行函数校验 -->
      <van-field
        v-model="driverMap.phone"
        @blur="selectDriverByPhone"
        name="phone"
        required
        label="联系方式"
        placeholder="司机手机号"
        :rules="[{ required: true, message: '请输入司机联系方式' }]"
      />
      <!-- 通过 pattern 进行正则校验 -->
      <van-field
        v-model="driverMap.realName"
        name="realName"
        label="司机姓名"
        required
        placeholder="司机姓名"
        :rules="[{ required: true, message: '请输入司机姓名' }]"
      />
      <!-- 通过 validator 进行异步函数校验 -->
      <van-field
        v-model="driverMap.idCert"
        name="idCert"
        required
        label="身份证号"
        placeholder="司机身份证号"
        :rules="[{ required: true, message: '请输入司机身份证号' }]"
      />
       <!-- 车牌号 -->
      <van-field
        v-model="carMap.carNo"
        name="carNo"
        label="车牌号"
        required
        placeholder="请输入司机车牌号"
        :rules="[{ required: true, message: '请输入司机车牌号' }]"
      />
      <van-field
        readonly
        clickable
        name="area"
        :value="address.name"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />

      <van-popup v-model="showArea" round  position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="selectAddress"
          cancel-button-text="全部地区"
          @cancel="showArea = false"
          :columns-placeholder="['请选择', '全部', '全部']"
        />
      </van-popup>

      <van-field
        v-model="driverMap.address"
        name="address"
        label="街道地址"
        placeholder="街道地址"
      />
      <van-field
        v-model="driverMap.qualCert"
        name="qualCert"
        label="从业资格证号"
        placeholder="司机从业资格证号"
      />
      <van-field
        v-model="driverMap.driverLabel"
        name="driverLabel"
        label="标签"
        placeholder="司机标签"
      />
      <van-field
        label="驾照类型"
        readonly
        clickable
        name="licType"
        placeholder="点击选择驾照类型"
        @click="showLicType = true"
        :value="selectList.licType"
      />
      <!-- 驾照类型弹窗 -->
      <van-popup v-model="showLicType" round   position="bottom">
        <van-picker
          title="驾照类型"
          show-toolbar
          :columns="licType"
          @confirm="selectLicType"
          @cancel="showLicType = false"
        />
      </van-popup>

      <div class="uploadImg">上传身份证驾驶证图片
      </div>
      <div class="uploadImg_icon">
        <div class="upLoad_item_icon">
          <template v-if="fileList1.length!=1">
            <img src="../../assets/img/icon/upload.png" alt="">
            <p>请上传司机身份证正面照片</p>
          </template>
          <div class="upLoad_item_icon_upload">
            <van-uploader v-model="fileList1" :max-count="1" :before-read="beforeRead" :after-read="afterRead1" />
          </div>
        </div>
        <div class="upLoad_item_icon">
          <template v-if="fileList2.length!=1">
            <img src="../../assets/img/icon/upload.png" alt="">
            <p>请上传司机驾驶证照片</p>
          </template>
          <div class="upLoad_item_icon_upload">
            <van-uploader v-model="fileList2" :max-count="1" :before-read="beforeRead" :after-read="afterRead2" />
          </div>
        </div>
      </div>
      <div class="uploadImg_icon">
        <div class="upLoad_item_icon">
          <template v-if="fileList3.length!=1">
            <img src="../../assets/img/icon/upload.png" alt="">
            <p>请上传司机手持身份证照片</p>
          </template>
          <div class="upLoad_item_icon_upload">
            <van-uploader v-model="fileList3" :max-count="1" :before-read="beforeRead" :after-read="afterRead3" />
          </div>
        </div>
        <div class="upLoad_item_icon">
          <template v-if="fileList4.length!=1">
            <img src="../../assets/img/icon/upload.png" alt="">
            <p>请上传司机从业资格证照片</p>
          </template>
          <div class="upLoad_item_icon_upload">
            <van-uploader v-model="fileList4" :max-count="1" :before-read="beforeRead" :after-read="afterRead4" />
          </div>
        </div>
      </div>
  <!-- 车辆信息 -->

      <!-- <van-field
        name="captainNameId"
        label="所属车队"
        placeholder="点击选择所属车队"
        readonly
        clickable
        @click="showCarGroupClick"
        :value="selectList.carGroup"
        v-if="showCarGroupInput"
      /> -->
        <!-- 所属车队弹窗 -->
      <van-popup v-model="showCarGroup" round   position="bottom">
        <van-picker
          title="所属车队"
          show-toolbar
          :columns="carGroup"
          @confirm="selectCarGroup"
          @cancel="showCarGroup = false"
        />
      </van-popup>
      <van-field
        v-model="carMap.mdlName"
        name="mdlName"
        label="品牌型号名称"
        @blur="selectMdlName"
        placeholder="请输入品牌型号名称"
      />
      <van-field
        v-model="carMap.mdlLoad"
        name="mdlLoad"
        label="载重(t)"
        placeholder="载重"
        :readonly="readonly?true:false"
      />
      <van-field
      v-model="carMap.mdlLoadRate"
        name="mdlLoadRate"
        label="载重装载率(%)"
        placeholder="载重装载率"
        :readonly="readonly?true:false"
      />
      <van-field
        v-model="carMap.mdlVolume"
        name="mdlVolume"
        label="容积(m³)"
        placeholder="容积"
        :readonly="readonly?true:false"
    />
      <van-field
        v-model="carMap.mdlVolumeRate"
        name="mdlVolumeRate"
        label="体积装载率(%)"
        :readonly="readonly?true:false"
        placeholder="体积装载率"
      />
        <van-field
        name="carType"
        label="车辆种类"
        placeholder="点击选择车辆种类"
        readonly
        clickable
        @click="showCarType = true"
        :value="selectList.carType"
      />
        <!-- 车辆种类弹窗 -->
      <van-popup v-model="showCarType" round   position="bottom">
        <van-picker
          title="车辆种类"
          show-toolbar
          :columns="carType"
          @confirm="selectCarType"
          @cancel="showCarType = false"
        />
      </van-popup>
      <van-field
        name="carClass"
        label="车辆分类"
        placeholder="点击选择车辆分类"
        readonly
        clickable
        @click="showCarClass = true"
        :value="selectList.carClass"
      />
    <!-- 车辆分类弹窗 -->
      <van-popup v-model="showCarClass" round   position="bottom">
        <van-picker
          title="牌照类型"
          show-toolbar
          :columns="carClass"
          @confirm="selectCarClass"
          @cancel="showCarClass = false"
        />
      </van-popup>

      <van-field
        name="carNoType"
        readonly
        clickable
        placeholder="点击选择牌照类型"
        label="牌照类型"
        @click="showCarNoType = true"
        :value="selectList.carNoType"
      />
      <!-- 驾照类型弹窗 -->
      <van-popup v-model="showCarNoType" round   position="bottom">
        <van-picker
          title="牌照类型"
          show-toolbar
          :columns="carNoType"
          @confirm="selectCarNoType"
          @cancel="showCarNoType = false"
        />
      </van-popup>
      <van-field
        v-model="carMap.roadPermit"
        name="roadPermit"
        label="道路运输许可证"
          placeholder="请输入道路运输证"
      />
      <!-- 默认企业司机 -->
      <van-field
        name="deptId"
        readonly
        clickable
        @click="showDeptId = true"
        :value="selectList.deptId"
        label="注册类型"
      />
      <!-- 注册类型弹窗 -->
      <van-popup v-model="showDeptId" round   position="bottom">
        <van-picker
          title="牌照类型"
          show-toolbar
          :columns="deptId"
          @confirm="selectDeptId"
          @cancel="showDeptId = false"
        />
      </van-popup>
      <van-field
        v-model="carMap.carLabel"
        name="carLabel"
        label="标签"
          placeholder="请输入标签"
      />
      <van-field
        v-model="carMap.mdlRemarks"
        name="mdlRemarks"
        label="备注"
          placeholder="请输入备注信息"
      />
      <div class="uploadImg">上传车辆图片</div>
      <van-uploader v-model="fileList" :max-count="6" :before-read="beforeRead" @delete="deleteImg" :after-read="afterRead" />

      <div class="sub_btn" style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <div v-else>
      <van-empty description="注册成功" />
    </div>
  </div>
</template>
<script>
import AreaList from '@/utils/area.js'
import NavBar from '../../components/NavBar/NavBar.vue'
import { Toast } from 'vant';
import lrz from 'lrz'
import {checkPhone,carNumber,IDCard} from '@/utils/utils'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      status:0,//0为新增 1为修改企业
      registerShow:true,//当为扫码注册时，是否显示注册成功
      currentUrl:'',
      currentSearch:'',
      CodedeptId:'',//二维码传过来的deptid
      strCarId:'',//二维码传过来的车队id
      tel:'',//二维码传过来的手机号
      params:{
        userName: '',
        tel: '',
        sfz: '',
        cyzgh: '',
        tag: '',
        AreaInfo: '',
        area:'',
        carNum:'',
      },
      selectList:{//下拉框给用户展示的数据
        licType:'',//驾照类型
        carNoType:'',//牌照类型
        deptId:'企业司机',//注册类型
        carClass:'',//车辆类型
        carType:'',//车辆种类
        carGroup:'',//所属车队
      },
      driverMap:{
        // realName:"", //司机名
        realName:"", //司机名
        // phone:"",//电话
        phone:"",//电话
        deptId:"",//企业司机depid 公共司机0
        // idCert:"",//身份证
        idCert:"",//身份证
        province:"",
        city:"",
        area:"",
        address:"",
        licType:"",//驾驶证类型
        driverLabel:"",//标签
        qualCert:"",//,从业资格号
        idCertFpic:"",//司机身份证前照片
        idCertBpic:"",//手持身份照片
        qualCertPic:"",//从业资格证照片
        driveCertPic:""//司机驾驶证照片

      },
      carMap:{
        deptId:"",//车辆注册类型
        // carNo:"",//车牌号
        carNo:"",//车牌号
        carClass:"",//车辆分类
        carType:"",//车辆种类
        carNoType:"",//牌照类型
        carLabel:"",//标签
        roadPermit:"",//道路运输许可证
        pics: [],//图片
        captainNameId:"",//所属车队队长ID
        mdlName:"",//,品牌型号名称 判断如果有不新增
        mdlLoad:"",//载重
        mdlLoadRate:"",//载重率
        mdlVolume:"",//溶剂
        mdlVolumeRate:"",//体积装载率,
        mdlRemarks:""//备注
      },
      areaList:AreaList,
      showArea:false,//显示城市选择
      showLicType:false,//显示驾照类型
      showCarNoType:false,//显示拍照类型
      showDeptId:false,//显示注册类型
      showCarClass:false,//显示车辆种类
      showCarType:false,//显示车辆类型
      showCarGroup:false,//显示车队弹框
      readonly:false,//是否禁止输入
      pattern: /\d{6}/,
      datatime:'2019-01-25',
      navInfo: {
        title: '注册司机',
        left:true,
        path:'/home'
      },
      address: {            // 地址选择器
        show: false,
        code: '',
        name: ''
      },
      licType:[//驾照类型
        {id:'1',text:'A1'},
        {id:'2',text:'A2'},
        {id:'3',text:'A3'},
        {id:'4',text:'B1'},
        {id:'5',text:'B2'},
        {id:'6',text:'B3'},
      ],
      carType:[//车辆类型
        {id:'1',text:'小面'},
        {id:'2',text:'金杯'},
        {id:'3',text:'箱货'},
        {id:'4',text:'小型平板'},
        {id:'5',text:'大型平板'},
      ],
      carNoType:[//牌照类型
        {id:'01',text:'黄牌'},
        {id:'02',text:'蓝牌'},
        {id:'99',text:'其他'},
      ],
      deptId:[//选择注册类型
        {id:'0',text:'公共司机'},
        {id:'',text:'企业司机'},
      ],
      carClass:[//选择车辆分类
        {id:'1',text:'新能源车'},
        {id:'2',text:'小面'},
        {id:'3',text:'燃油车'},
        {id:'4',text:'混合动车'},
      ],
      carGroup:[//选择车队队长
      ],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList:[],
      showCarGroupInput:true,
      transportId:'',//传过来的计划号
      waybillNo:'',//传过来的运单号
      oldDriverId:null,//旧的司机ID
      updateDriverType:null,//追加司机1 替换2
    }
  },
  created() {
    console.log(this.$route.query.type ==3);
    console.log(this.$route.query.type ==3&&window.sessionStorage.getItem('transportNoId'));
      // type=1是手动录入
    if (this.$route.query.type ==1) {
      this.driverMap.deptId = this.carMap.deptId =this.deptId[1].id = this.$store.state.userInfo.deptId;
      this.tel = this.$store.state.userInfo.userName;
      this.CodedeptId = this.$store.state.userInfo.deptId
      this.navInfo.path='/home'
       this.showCarGroupInput = true
    } else if(this.$route.query.type ==3 &&window.sessionStorage.getItem('transportNoId')) {//如果是从计划详情过来的
      console.log(window.sessionStorage.getItem('transportNoId'));
      this.navInfo.left=true
      this.navInfo.path='/planDetails'
      this.transportId =window.sessionStorage.getItem('transportNoId')
      this.updateDriverType = 1
      if (this.$route.query.driverInfo) {//如果修改司机信息有传来的司机信息则回显
        this.driverMap.realName = this.$route.query.driverInfo.driverName
        this.carMap.carNo = this.$route.query.driverInfo.carNo
        this.driverMap.phone = this.$route.query.driverInfo.phone
        this.oldDriverId = this.$route.query.driverInfo.driverId
        this.updateDriverType = 2
      }
    } else if(this.$route.query.type ==4 &&window.sessionStorage.getItem('waybillNo')) {//如果是从计划详情过来的
      console.log(window.sessionStorage.getItem('waybillNo'));
      this.navInfo.left=true
      this.navInfo.path='/waybillDetails'
      this.waybillNo =window.sessionStorage.getItem('waybillNo')
      this.updateDriverType = 1
      if (this.$route.query.driverInfo) {//如果修改司机信息有传来的司机信息则回显
        this.driverMap.realName = this.$route.query.driverInfo.driverName
        this.carMap.carNo = this.$route.query.driverInfo.carNo
        this.driverMap.phone = this.$route.query.driverInfo.phone
        this.oldDriverId = this.$route.query.driverInfo.driverId
        this.updateDriverType = 2
      }
    }
    else {//处理拼接过来的参数
      this.navInfo.left=false
      this.currentUrl = location.href
      let numStr1 = 0
      let numStr2 = 0
      let numStr3 = 0
      //出现=的位置
        numStr1 = this.currentUrl.indexOf('?')
        numStr2 = this.currentUrl.indexOf('&')
        numStr3 = this.currentUrl.indexOf('=')
      // deptId
      let strIditem = this.currentUrl.substring(numStr1+1,numStr2)
      this.CodedeptId = strIditem
      this.tel = this.currentUrl.substring(numStr3 +1,this.currentUrl.length)
      // 车队ID
      this.strCarId = this.currentUrl.substring(numStr2 +3,numStr3 - 1)
      if (this.strCarId!='') {
        this.showCarGroupInput = false
      } else {
        this.showCarGroupInput = true
      }
      this.driverMap.deptId = this.carMap.deptId =this.deptId[1].id = this.CodedeptId;
      // this.carMap.captainNameId = this.strCarId
    }
    this.getCarGroup()
  },
   methods: {
    async  selectDriverByPhone() {
      let params = {
        phone:this.driverMap.phone
      }
      let res = await this.$Api.selectDriverByPhone(params)
      console.log(res);
      if (!res.data) {
        this.status = 0;
        return false
      }
      this.status = 1;
      this.driverMap.realName = res.data.realName
      this.carMap.carNo = res.data.carNo
      this.driverMap.idCert = res.data.idCert

     },
     //获取第几次字符出现的位置
       find(str,cha,num){
        var x=str.indexOf(cha);
        for(var i=0;i<num;i++){
            x=str.indexOf(cha,x+1);
        }
        return x;
      },
     //获取车队信息
    async getCarGroup() {
      // console.log(this.$store.state.userInfo);
      let res =await this.$Api.queryCarGroup(this.CodedeptId)
      let dateList = res.data
      dateList.map((item,index) => {
        this.carGroup.push(
          {
            id:item.id,
            text:item.captainName
          })
      })
    },
    //选择地址
    selectAddress(values) {
      this.address.name = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
        this.driverMap.province = values[0].name.substring(0,values[0].name.length-1)
        this.driverMap.city = values[1].name.substring(0,values[0].name.length-1)
        this.driverMap.area = values[2].name
        // console.log(this.driverMap.province,this.driverMap.city,this.driverMap.area);
        //  province:"北京",
        // city:"北京",
        // area:"丰台区",
      this.showArea = false;
    },
    //选择驾照类型
    selectLicType(value, index) {
      this.driverMap.licType = value.id;
      this.selectList.licType = value.text;
      this.showLicType = false
    },
    //选择车辆牌照类型
    selectCarNoType(value, index) {
      this.carMap.carNoType = value.id
      this.selectList.carNoType = value.text
      this.showCarNoType = false
    },
    //选择注册类型
    selectDeptId(value) {
      this.carMap.deptId = value.id
      this.selectList.deptId = value.text
      this.showDeptId = false
    },
    //选择车辆分类
    selectCarClass(value) {
      this.carMap.carClass = value.id
      this.selectList.carClass = value.text
      this.showCarClass = false
    },
    //选择车辆种类
    selectCarType(value) {
      this.carMap.carType = value.id
      this.selectList.carType = value.text
      this.showCarType = false
    },
    //选择所属车队
    selectCarGroup(value) {
      this.carMap.captainNameId = value.id
      this.selectList.carGroup = value.text
      this.showCarGroup = false
    },
    //显示车队信息弹出框
    showCarGroupClick() {
      if (this.strCarId==''||this.strCarId==null) {
        this.showCarGroup=true
      }else {
        return false
      }

    },
    //当失去焦点获取品牌型号数据
    async  selectMdlName() {
      let params = {
        mdlName:this.carMap.mdlName
      }
      if (this.carMap.mdlName !='') {
        let res = await this.$Api.queryCarModel(params)
        if (res.data.msg=="数据重复") {
          this.carMap.mdlLoad = res.data.data.mdl_load
          this.carMap.mdlLoadRate = res.data.data.mdl_load_rate
          this.carMap.mdlVolume = res.data.data.mdl_volume
          this.carMap.mdlVolumeRate = res.data.data.mdl_volume_rate
          this.readonly = true
        } else {
          this.readonly = false
        }
      }

    },
    onCancel() {
       this.showCarType = false
      Toast('取消');
    },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator(val) {
      return /1\d{10}/.test(val);
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        Toast.loading('验证中...');

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    deleteImg(files,detail) {
      this.carMap.pics.splice(detail.index,1)
    },
    //将bade64转为file文件
    dataURLtoFile(dataurl, filename) { // 将base64转换为file文件
      // 参数dataurl base64字符串
      // 参数filename 图片名称
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },
    //上传车辆图片
    async  afterRead(files) {
      console.log(files);
      let param = new FormData()
      if (files.file.size > 1048576) {//根据图片字节去判断是否压缩图片 1M
        lrz(files.file,{ quality: 0.5}).then( async(rst)=> {
          console.log('rst',rst);
          let base64 = this.dataURLtoFile(rst.base64,files.file.name);
          console.log('base64',base64);
            param.append('file',base64)
            // console.log(this.$store.state.userInfo);
            let res = await this.$Api.uploadPicture(this.tel,param)
            if (res.data.code==200) {
              this.carMap.pics.push(res.data.data[0].Picture);
            } else {
              this.fileList=[]
              Toast("上传失败，请重新上传")
            }
        })
      } else {
        param.append('file',files.file)
        let res = await this.$Api.uploadPicture(this.tel,param)
        if (res.data.code==200) {
          this.carMap.pics.push(res.data.data[0].Picture);
        } else {
          this.fileList=[]
          Toast("上传失败，请重新上传")
        }
      }
    },
    //上传图片
    async afterRead1(files) {
      console.log(files);
      let param = new FormData()
        if (files.file.size > 1048576) {//根据图片字节去判断是否压缩图片 1M
        lrz(files.file,{ quality: 0.5}).then( async(rst)=> {
          console.log('rst',rst);
          let base64 = this.dataURLtoFile(rst.base64,files.file.name);
            param.append('file',base64)
            console.log(param);
            let res = await this.$Api.uploadPicture(this.tel,param)
            if (res.data.code==200) {
              this.driverMap.idCertFpic = res.data.data[0].Picture;
            } else {
               this.fileList1 = []
              Toast("上传失败，请重新上传")
            }
        })
      } else {
        param.append('file',files.file)
        let res = await this.$Api.uploadPicture(this.tel,param)
        if (res.data.code==200) {
          this.driverMap.idCertFpic = res.data.data[0].Picture;
        } else {
          this.fileList1 = []
          Toast("上传失败，请重新上传")
        }
      }
    },
    async afterRead2(files) {
       let param = new FormData()
        if (files.file.size > 1048576) {//根据图片字节去判断是否压缩图片 1M
        lrz(files.file,{ quality: 0.5}).then( async(rst)=> {
          let base64 = this.dataURLtoFile(rst.base64,files.file.name);
            param.append('file',base64)
            let res = await this.$Api.uploadPicture(this.tel,param)
            if (res.data.code==200) {
               this.driverMap.driveCertPic = res.data.data[0].Picture;
            }else {
               this.fileList2 = []
              Toast("上传失败，请重新上传")
            }
        })
      } else {
        param.append('file',files.file)
        let res = await this.$Api.uploadPicture(this.tel,param)
        if (res.data.code==200) {
           this.driverMap.driveCertPic = res.data.data[0].Picture;
        } else {
          this.fileList2 = []
          Toast("上传失败，请重新上传")
        }
      }
    },
    async afterRead3(files) {
       let param = new FormData()
        if (files.file.size > 1048576) {//根据图片字节去判断是否压缩图片 1M
        lrz(files.file,{ quality: 0.5}).then( async(rst)=> {
          let base64 = this.dataURLtoFile(rst.base64,files.file.name);
            param.append('file',base64)
            let res = await this.$Api.uploadPicture(this.tel,param)
            if (res.data.code==200) {
               this.driverMap.idCertBpic = res.data.data[0].Picture;
            }else {
              this.fileList3 = []
              Toast("上传失败，请重新上传")
            }
        })
      } else {
        param.append('file',files.file)
        let res = await this.$Api.uploadPicture(this.tel,param)
        if (res.data.code==200) {
           this.driverMap.idCertBpic = res.data.data[0].Picture;
        } else {
          this.fileList3 = []
          Toast("上传失败，请重新上传")
        }
      }
    },
    async afterRead4(files) {
      let param = new FormData()
      param.append('file',files.file)
      let res = await this.$Api.uploadPicture(this.tel,param)
      if (res.data.code==200) {
        this.driverMap.qualCertPic = res.data.data[0].Picture;
      } else {
        Toast("上传失败，请重新上传")
        this.fileList4 = []
      };
    },
    //上传之前的验证
    beforeRead(file) {
      if(file.type !='image/jpeg'&&file.type !='image/png') {
        Toast('请上传jpg或png格式的图片')
        return false
      }
      return true
    },
    //提交
    async onSubmit(values) {
      if (!checkPhone(values.phone)) {
        Toast('请输入正确的手机号')
        return false
      }
      if (!IDCard(values.idCert)) {
        Toast('请输入正确的身份证号')
        return false
      }
      // if (!carNumber(values.carNo)) {
      //   Toast('请输入正确的车牌号')
      //   return false
      // }
      let params = {
        status:this.status.toString(),
        driverMap:this.driverMap,
        carMap:this.carMap,
        userId:!this.$store.state.userInfo.id ? this.CodedeptId : this.$store.state.userInfo.id,
      }

      if (this.navInfo.path=='/planDetails') {//从计划详情新增
        params.transportId = this.transportId
        params.driverMap.deptId = params.carMap.deptId = this.$store.state.userInfo.deptId
        params.updateDriverType = this.updateDriverType
      } else if(this.navInfo.path=='/waybillDetails') {//从订单详情新增
        params.waybillNo = this.waybillNo
        params.driverMap.deptId = params.carMap.deptId = this.$store.state.userInfo.deptId
        params.updateDriverType = this.updateDriverType
      }else { //普通新增
        delete params.transportId
        delete params.waybillNo
        params.updateDriverType = 3
      }
      if (this.$route.query.driverInfo) {//如果有传过来的司机信息，则是修改需要上传oldDriverId
        params.oldDriverId = this.oldDriverId
      }
      let res = await this.$Api.addDriverModify(params)
      if (res.data=="注册成功") {
        Toast("录入信息成功")
        //手动录入
        if (this.$route.query.type ==1) {
          this.registerShow = true
        } else {
          this.registerShow = false
        }
        //  console.log(this.$route.query.type ==3);
        // console.log(this.$route.query.type ==3&&window.sessionStorage.getItem('transportNoId'));
        // 表单重置
        for (const key in this.driverMap) {
          this.driverMap[key] = ''
        }
        for (const key in this.carMap) {
          if (Array.isArray(this.carMap[key])) {
            this.carMap[key] = []
          } else {
            this.carMap[key] = ''
          }
        }
        for (const key in this.selectList) {
          this.selectList[key] = ''
        }
        this.selectList.deptId = "企业司机"
        this.address.name = ''
        this.fileList1 = []
        this.fileList2 = []
        this.fileList3 = []
        this.fileList4 = []
        this.fileList = []
        // this.driverMap.deptId = this.carMap.deptId =this.deptId[1].id = this.$store.state.userInfo.id || this.CodedeptId;
        this.driverMap.deptId = this.carMap.deptId = this.$store.state.userInfo.id;
      }else if(res.data=="修改成功") {
         Toast("修改司机成功")
        //手动录入
        if (this.$route.query.type ==1) {
          this.registerShow = true
        } else {
          this.registerShow = false
        }
        // 表单重置
        for (const key in this.driverMap) {
          this.driverMap[key] = ''
        }
        for (const key in this.carMap) {
          if (Array.isArray(this.carMap[key])) {
            this.carMap[key] = []
          } else {
            this.carMap[key] = ''
          }
        }
        for (const key in this.selectList) {
          this.selectList[key] = ''
        }
        this.selectList.deptId = "企业司机"
        this.address.name = ''
        this.fileList1 = []
        this.fileList2 = []
        this.fileList3 = []
        this.fileList4 = []
        this.fileList = []
        // this.driverMap.deptId = this.carMap.deptId =this.deptId[1].id = this.$store.state.userInfo.id || this.CodedeptId;
        this.driverMap.deptId = this.carMap.deptId = this.$store.state.userInfo.id;
      }else if(res.data=="新增成功") {
         Toast("新增成功成功")
        //手动录入
        if (this.$route.query.type ==1) {
          this.registerShow = true
        } else {
          this.registerShow = false
        }
        // 表单重置
        for (const key in this.driverMap) {
          this.driverMap[key] = ''
        }
        for (const key in this.carMap) {
          if (Array.isArray(this.carMap[key])) {
            this.carMap[key] = []
          } else {
            this.carMap[key] = ''
          }
        }
        for (const key in this.selectList) {
          this.selectList[key] = ''
        }
        this.selectList.deptId = "企业司机"
        this.address.name = ''
        this.fileList1 = []
        this.fileList2 = []
        this.fileList3 = []
        this.fileList4 = []
        this.fileList = []
        this.driverMap.deptId = this.carMap.deptId = this.$store.state.userInfo.id;
      }
      else {
        Toast("录入信息失败")
      }
    },
  },
}
</script>
<style lang="scss" scoped>
html,body {
  // width: 100vw;
  box-sizing: border-box;
}
.register {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
//  /deep/.van-uploader__preview{
//     margin: 5px 5px 0px 10px !important;
//   }

  // #ff6701
  .van-button--info {
    background-color: #ff6701;

    border: 1px solid #ff6701;
  }
  .uploadImg {
    padding: 10px 16px;
    font-size: 14px;
    color: #646566;
  }
  .sub_btn {
    position: fixed;
    width: 80vw;
    height: 30px;
    bottom: 30px;
    left: 5vw;
  }
  .sub_from {
    height:calc(100% - 150px);
    overflow-y: auto;
  }

}
/deep/.van-field__label {
    width: 111px!important;
  }
.upLoad_list {
  display: flex;
  width: 100%;
  height: 110px;
  // background: pink;
  justify-content: center;
  align-items: center;
  .upLoad_list_item {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .upLoad_list_item_icon {
      position: absolute;
      bottom: 20%;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
      }
      p{
        color: #969799;
        font-size: 12px;
      }

    }
    /deep/.van-uploader {
      width: 100%;
    }
     /deep/.van-uploader__upload , /deep/.van-uploader__preview {
    margin-top: 5px;
    width: 100%;
    height: 100px;
  }
  }

}
.uploadImg_icon {
    width: 100%;
    height: 110PX;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;
    .upLoad_item_icon {
      // border-bottom: 1px solid #999999;
      margin: 0.2rem;
      position: relative;
      width: 50%;
      height: 100%;
      background: #f7f8fa;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .upLoad_item_icon_upload {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // opacity: 0;
      }
       img {
        width: 60px;
        height: 60px;
        padding: 10px 25% 0;
      }
      p{
        color: #969799;
        font-size: 12px;
        text-align: center;
      }
    }
      /deep/.van-uploader {
      width: 100%;
    }
    /deep/.van-uploader__upload  {
      opacity: 0;
    }
    /deep/.van-uploader__upload , /deep/.van-uploader__preview {
      margin-top: 5px;
      width: 100%;
      height: 100px;
    }
    /deep/.van-uploader__preview-image {
      width: 100%;
      height: 100px;
    }
  }

.carInfo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebedf0;
  .carInfo_item1 {
    flex: 2;
    /deep/.van-field__label {
      width: 60px!important;
    }
  }
  .carInfo_item2 {
    flex: 3;
    border-left: 1px solid #ebedf0;
    /deep/.van-field__label {
      width: 100px!important;
    }
  }
}
/deep/.van-uploader {
  margin-left: 0.2rem;
}
</style>